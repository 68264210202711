
body, html {
  background: #000;
}

.main-content {
  background: #fff;
}

header, footer {
  background-color: #000;

  .img-fluid {
    max-height: 50px;
    max-width: 130px;
    width: auto;
    height: auto;
    margin: 10px auto;
    display: block;
  }

}

footer {
  background: #000;
  color: #dcdcdc;
  font-size: 12px;
  padding-bottom: 30px;
  padding-top: 30px;

  hr {
    border-color: lighten(#000, 20%);
  }

  .h4 {
    border-bottom: 2px solid red;
    display: inline-block;
    padding-bottom: 3px;
    font-size: 18px;
  }

  a, a:hover, .btn-link, .btn-link:hover {
    color: #fff;
  }
}


.reference-bar {
  background: #000;
  color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &:last-child {
    border-bottom: 1px dashed #fff;
  }

  .h2 {
    font-size: 1.3rem;
    margin: 0;
  }

  a {
    color: #fff;

    &:hover {
      color: #dc3545 !important;
      text-decoration: none;
    }
  }
}

// override defaults from bootstrap

.btn-outline-danger:hover {
  color: darken(#dc3545, 20%);
  background-color: transparent;
}
