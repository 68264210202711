@import "../variables";

.radio-button-wrapper {

  .is-invalid {
    border-color: $color-invalid;
    color: $color-invalid;
  }

  .icon {
    visibility: hidden;
    font-size: 0.75rem;
    margin-right: 0.5rem;

    &.selected {
      visibility: visible;
    }
  }

  & input[type="radio"] {
    width: 0.1px;
    opacity: 0;
  }

  & label {
    width: 100%;
    text-transform: capitalize;
    font-size: 1rem !important;
    padding: 0.5rem;
    border-radius: 0.25rem;
    color: $color-dark-grey;
    border: 1px solid $color-grey;
    transition: all .2s ease-in-out;
    cursor: pointer;

    &:hover {
      color: $color-black;
      border-color: $color-black;
    }
  }

  input[type='radio']:checked {
    + label {
      color: $color-black;
      border-color: $color-black;
    }
  }

  @media (max-width: $screen-min-sm) {
    max-width: 100%;
    label {
      text-align: center;
    }
  }
}