@import "../variables";

.layout-schema-form {

  & .form-container {
    & .form-group {
      width: 100%;

      & input {
        text-align: left;
        padding: 1rem;
        border-style: none;
        outline: none;
        border-radius: 0;
        color: $color-dark-grey;
        font-size: $font-size-text;
        background: none;
        margin: 0 auto;

        &:focus {
          box-shadow: none;
          border-bottom: 1px solid $color-dark-grey;
        }

        &::placeholder {
          color: $color-grey;
        }

        &.is-invalid {
          border-bottom: 1px solid $color-error;
        }
      }

      & select {
        text-align-last: center;
        margin: 0 auto;
        width: 80%;
        border: none;
        border-bottom: 1px solid black;
        color: $color-dark-grey;
        outline: none;
        font-size: $font-size-text;
        text-transform: capitalize;
        background: none;
        border-radius: 0;

        &:focus {
          box-shadow: none;
          border-bottom: 1px solid $color-dark-grey;
        }

        option {
          color: $color-grey;
          background: none;

          & .is-invalid {
            border-bottom: 1px solid $color-error;
          }
        }
      }

      & .invalid-feedback {
        margin: 1.5rem;
        font-size: $font-size-text;
      }

      & .react-autosuggest__suggestions-container--open {
        width: 80%;
      }
    }

    //& .form-group {
      padding: 0;

      & .field {
        margin: 1rem;
        padding: 1rem;
        display: flex;
        justify-content: left;
        align-items: flex-start;
        flex-direction: column;

        & .form-label {
          font-size: 1.5rem;

          & .form-label_icon {
            margin-left: 0.5rem;
            font-size: 1rem;
          }
        }

      }

    //}

    & .submit-button {
      margin: 1rem;
      text-align: center;
    }
  }
}

.mobile-layout-schema-form {
  & .form-container {
    & .form-group {
      & input {
        text-align: center;
      }
    }
  }
  & .field {
    & .form-label {
      display: flex;
      justify-content: space-around;

      & span {
        align-self: center;
      }
    }

    text-align: left;
    align-items: center !important;

    & .select-box-container {
      justify-content: center;

      & .select-box_option {
        width: 100%;
      }
    }
  }


}