@import "../variables";

.quote-wrapper {
  .title-wrapper {
    background: $color-dark-grey;
    color: #fff;
    padding: 15px;

    h1 {
      text-align: center;
      font-size: 1.5rem;
      margin: 0;
    }
  }
}
