@import "../variables";

.contact-bubble {
  padding: 15px;
  background: $color-light-grey;
  border-radius: 10px;
  border-top-right-radius: 0;
  width: 90%;
  margin-top: 15px;
  text-align: right;
  position: relative;

  @media (min-width: $screen-min-md) {
    padding: 20px;
  }
  @media (min-width: $screen-min-lg) {
    padding: 30px;
  }

  &:after {
    position: absolute;
    right: -30px;
    top: 0;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 30px 0 0;
    border-color: $color-light-grey transparent transparent transparent;
  }

  .need-help {
    font-size: 0.75rem;

    @media (min-width: $screen-min-lg) {
      font-size: 0.9rem;
    }
  }

  .number {
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 2rem;

    @media (min-width: $screen-min-lg) {
      font-size: 1.5rem;
    }
  }

  .friendly {
    font-size: 0.8rem;
  }

  .hours {
    font-size: 0.9rem;
    margin-top: 15px;
  }
}

.step-form-wrapper {
  .title-wrapper {
    background: $color-dark-grey;
    color: #fff;
    padding: 15px;

    h1 {
      text-align: center;
      font-size: 1.5rem;
      margin: 0;
    }
  }

  .form-wrapper {
    background: $color-light-grey;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
    & .info:first-letter {
      text-transform: uppercase;
    }
    h2 {
      font-size: 1.5rem;
    }

    label {
      font-size: 1.7rem;
      font-weight: 600;
    }

    @media (min-width: $screen-min-sm) {
      padding: 25px;
    }
    @media (min-width: $screen-min-md) {
      padding: 40px;
    }
    @media (min-width: $screen-min-lg) {
      padding: 60px;
    }
  }


  .reference-bar .h2 {
    font-size: 1rem;
    text-align: center;

    a {
      margin-top: 3px;
      display: block;
    }
  }
}

.grid-container {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'content content side';
  grid-gap: 5px;
}

.layout-step-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  & .title {
    font-size: 1.25rem;
    text-transform: capitalize;
    font-style: italic;
    margin-top: 5px;
    text-align: center;

    & p {
      margin: 0;
    }
  }

  & .form-container {
    text-align: center;
    //margin: 5px;
    width: 100%;

    & .form-label {
      font-size: $font-size-label;
      margin: 5px;

      &.is-invalid {
        color: $color-error;
      }

      span {
        display: none;
      }
    }

    & .form-group {
      & input {
        width: 80%;
        text-align: center;
        padding: 1rem;
        border-style: none;
        outline: none;
        border-radius: 0;
        color: $color-dark-grey;
        font-size: $font-size-text;
        background: none;
        margin: 0 auto;

        &:focus {
          box-shadow: none;
          border-bottom: 1px solid $color-dark-grey;
        }

        &::placeholder {
          color: $color-grey;
        }

        &.is-invalid {
          border-bottom: 1px solid $color-error;
        }
      }

      & select {
        text-align-last: center;
        margin: 0 auto;
        width: 80%;
        border: none;
        border-bottom: 1px solid black;
        color: $color-dark-grey;
        outline: none;
        font-size: $font-size-text;
        text-transform: capitalize;
        background: none;
        border-radius: 0;

        &:focus {
          box-shadow: none;
          border-bottom: 1px solid $color-dark-grey;
        }

        option {
          color: $color-grey;
          background: none;

          & .is-invalid {
            border-bottom: 1px solid $color-error;
          }
        }
      }

      & .invalid-feedback {
        margin: 1.5rem;
        font-size: $font-size-text;
      }

      & .react-autosuggest__suggestions-container--open {
        width: 80%;
      }
    }
  }

  & .side-bar {

    grid-area: side;
    border-left: 0.5px solid $color-black;
    background: $side-bar-bg;
    text-align: center;
    height: 100%;

    & h3 {
      margin-top: 1rem;
    }

    & p {
      text-align: justify;
      padding: 1rem;
    }
  }
}

.desktop-layout-step-form {
  grid-area: content;

  & .form-container {
    & .list-of-three {
      width: 50% !important;
    }

    & .multi-options {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
    }
  }


  & .btn-next {
    margin-top: 5rem;
  }
}


.mobile-layout-step-form {
  height: 100vh;
  width: 100%;
  padding: 5px;

  & .title {
    height: 20vh;
  }

  & .form-container {
    height: 60vh;

    //& .form-group {
    /*height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;*/


    //& .form-item {
    /*display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;*/

    & input {
      background: red;
      text-align: center;
      padding: 5px;
      font-size: 1rem;
    }

    select {
      width: 80%;
    }

    & .auto-suggest {
      width: 80%;
    }

    & .list-of-three {
      width: 100% !important;
    }

    & .multi-options {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 5px;
    }


    & .info {
      margin-top: 0.5rem;
    }

    //}
    //}

  }

  & .btn-container {
    height: 20vh;
  }
}

.btn-next {
  border: none;
  margin-top: 10px;
  font-size: 1.25rem !important;
  text-decoration: none !important;
  color: $color-white !important;
  background-color: $color-red !important;
  text-transform: capitalize;
  transition: color 0.5s ease;

  &:hover {
    background-color: darken($color-red, 10%) !important;
  }

  & span {
    font-size: 1rem;
  }
}


.btn-previous {
  border-radius: 50% !important;
  width: 55px;
  height: 55px;
  font-size: 1.1rem;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.44);
  transition: all 0.5s ease-in-out;
  margin: 5px 0 0 5px;

  &:hover {
    color: $color-red !important;
    border: none;
    box-shadow: 0 0 2px 2px rgba(240, 0, 0, 1);
  }

  & span {
    font-size: 1.25rem;
  }
}