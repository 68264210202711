
.page-thank-you {
  a {
    color: #000000;

    &:hover {
      color: #dc3545 !important;
      text-decoration: none;
    }
  }
}
