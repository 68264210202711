.form-wizard {
  margin: 15px auto;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;

  .form-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 20vh;

    .form {
      width: 100%;
      margin: 0 auto;

      .form-btn-add {
        display: block;
        margin: 0 auto;
      }

      .form-btn-remove {
        display: block;
        margin: 0 auto;
      }
    }
  }
}

.form-list-item {
  flex: 1 0 auto;
  display: flex;
  margin: 10px auto;
  align-items: center;
  justify-content: center;

}


.form-button-control {
  text-align: center;
}

