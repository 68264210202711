.vehicle-info {
  margin: 20px 10px;

  .vehicle-info__preview-title {
    text-align: center;
  }

  .vehicle-info__preview-data {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.vehicle-result {
  border-radius: 4px;
  padding-top: 5px;
  margin-bottom: 5px;
  background: #fff;

  table {
    background: transparent;
  }
}