.form-item-group {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  .form-item {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    label {
      padding: 10px;
      text-align: right;
      flex: 1 0 20%;
    }

    input {
      flex: 1 0 50%;
    }

    select {
      flex: 1 0 50%;
    }
  }

  .form-item-error {
    margin-top: 0.5rem;
    display: inline-block;
    border-radius: 5px;
    color: #DE3A11;
    background: #FFE3E3;
    padding: .5rem 1rem;
    text-align: center;
    align-self: center;
  }
}


.formik-auto-suggest {
  flex: 1 0 50%;
}

