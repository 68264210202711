.svg {
  display: block;
  margin: 20px auto;
  max-width: 100%;

  .svg-circle-bg {
    fill: none;
  }

  .svg-circle {
    fill: none;
  }

  .svg-circle-text {
    font-size: 2rem;
    text-anchor: middle;
    font-weight: bold;
  }

}