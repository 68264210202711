@import "styles/layout";
@import "styles/fields/datepicker";
@import "styles/fields/autosuggest";
@import "./styles/stepper";
@import "./styles/tab";
@import "styles/van/van.form";
@import "styles/fields/input.field";
@import "styles/van/reg";
@import "styles/van/vehicle.info";

@import "styles/fields/radio.button";
@import "styles/fields/radio.group";
@import "styles/fields/dropdown.date.picker";

//styles for van step form
@import "styles/step.form/step.form";
//styles for van schema form
@import "styles/schema.form/schema.form";
//styles for quotes
@import "styles/quote/quote";

@import "styles/redirect/redirect";

@import "styles/circular.progress.bar";

@import "styles/pagination.scss";

@import "styles/sidebar";

@import "styles/bubbles.scss";

.form-van-step-container {
  background: linear-gradient(
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    url("images/step.form.bcg.png") center/cover no-repeat;
}

.form-van-schema-container {
  padding: 0;
  margin: 0;
  background: linear-gradient(
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    url("images/step.form.bcg.png") center/cover no-repeat;
}

.home-wrapper {
  min-height: 500px;
}
