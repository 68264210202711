@import './variables';

.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  width: 40%;
  height: 100%;
  transform: translateX(100%);
  border-left: 2px solid #bd2020;
  background: #FFFFFF;
  z-index: 2;
  overflow: scroll;
  padding: 2rem;
  transition: all 0.3s ease-in-out;

  &.show {
    transform: translateX(0);

  }

  @media (max-width: $screen-min-md) {
    width: 80%;
  }
}


