.step-nav {
  display: flex;
  justify-content: space-between;
  counter-reset: step;

  .nav-item {
    text-transform: uppercase;
    min-width: 60px;
  }

  .active {
    background: darkred;

    a {
      color: white;
    }
  }
}
