.bubble {
  min-height: 30px;
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  white-space: pre-line;
  text-align: left;
}

.question {
  align-items: flex-start;
  color: grey;

  .bubble {
    margin: 1rem;
    margin-right: 15%;
    padding: 0.8rem;
    background-color: #eee;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      bottom: 0;
      left: -7px;
      height: 20px;
      width: 20px;
      background: #eee;
      border-bottom-right-radius: 15px;
    }

    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: -10px;
      width: 10px;
      height: 20px;
      background: #6c757d;
      border-bottom-right-radius: 10px;
    }
  }
}

.answer {
  align-items: flex-end;

  .bubble {
    width: 100%;
    color: white;
    margin-left: 100%;
    background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
    background-attachment: fixed;
    position: relative;

    h6 {
      font-size: 0.9rem;
      text-transform: uppercase;
      text-decoration: underline #fff dashed;
      text-underline-position: under;
    }

    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      bottom: 0;
      right: -8px;
      height: 20px;
      width: 20px;
      background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
      background-attachment: fixed;
      border-bottom-left-radius: 15px;
    }

    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: -10px;
      width: 10px;
      height: 20px;
      background: #6c757d;
      border-bottom-left-radius: 10px;
    }

    @media (max-width: 768px) {
      width: 50%;
      margin-left: 25%;
    }
  }
}
