@import "../variables";

.drop-down-date-picker-wrapper {
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: repeat(3, 0.3fr);
  color: red;

  .invalid-feedback {
    grid-column: 1 / 4;
  }

  @media screen and (max-width: $screen-min-md) {
    grid-template-columns: repeat(3, 0.3fr);
  }
  @media screen and (max-width: $screen-min-sm) {
    grid-template-columns: 1fr;
    grid-gap: 0;
    .invalid-feedback {
      grid-column: 1 / 2;
    }
  }


}


